// NotFound.js
import React from 'react';
import { Link } from 'react-router-dom';

const Spinner = () => {
  return (
    <div className="spinner">
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default Spinner;
