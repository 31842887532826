// Componente para exibir o ROI
import React from 'react';

const ReturnOnInvestment = ({ percentage = "7,15%" }) => (
  <div>
    {percentage}
  </div>
);

export default ReturnOnInvestment;
