// Componente para exibir os Impostos Mensais Estimados
import React from 'react';

const EstimatedMonthlyTaxes = ({ percentage = "10%" }) => (
  <div>
    {percentage}
  </div>
);

export default EstimatedMonthlyTaxes;
